






















































































import { Vue, Component, Watch } from 'vue-property-decorator'
import { apiWithdrawConfig, apiWithdrawConfigSet } from '@/api/setting/user'
@Component({
    components: {}
})
export default class WithdrawDeposit extends Vue {
    /** S Data **/
    form = {
        withdraw_way: [], // 提现方式：1-钱包余额；2-微信零钱；3-银行卡；4-微信收款码；5-支付宝收款码
        withdraw_min_money: 0, // 最低提现金额
        withdraw_max_money: 0, // 最高提现金额
        withdraw_service_charge: 0, // 提现手续费
        scene: 'withdraw' // 场景：user-用户设置；register-注册设置；withdraw-提现设置
    }
    // 预校验
    formRules = {
        withdraw_way: [
            {
                type: 'array',
                required: true,
                message: '默认需要保留至少一种提现方法',
                trigger: 'change'
            }
        ]
    }
    $refs!: { formRef: any }
    /** E Data **/

    // 获取用户提现设置
    getWithdrawDeposit() {
        apiWithdrawConfig()
            .then((res: any) => {
                // this.$message.success('数据请求成功!')
                this.form = res
            })
            .catch(() => {
                // this.$message.error('数据请求失败!')
            })
    }

    // 修改用户提现设置
    setWithdrawDeposit() {
        this.form.scene = 'withdraw' // 场景：user-用户设置；register-注册设置；withdraw-提现设置
        this.form.withdraw_min_money = Number(this.form.withdraw_min_money)

        this.$refs.formRef.validate((valid: any) => {
            // 预校验
            if (!valid) {
                return
            }
            apiWithdrawConfigSet(this.form)
                .then((res: any) => {
                    setTimeout(() => {
                        this.getWithdrawDeposit()
                    }, 50)
                })
                .catch(() => {
                    // this.$message.error('保存失败!')
                })
        })
    }
    /** S Life Cycle **/
    created() {
        this.getWithdrawDeposit()
    }
    /** E Life Cycle **/
}
